<template>
  <div>
    <CRow>
      <CCol>
        <CCard>
          <CCardHeader>
            Role List
          </CCardHeader>
          <CCardBody>
            <CButton class="mb-2 mr-2" @click="add" color="success"><CIcon name="cil-plus"/></CButton>
            <CButton class="mb-2" @click="getData" color="info"><CIcon name="cil-reload"/></CButton>
            <div class="position-relative table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>Name</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody class="position-relative">
                  <tr v-for="(role, i) in roles" :key="role.code">
                    <td>{{ role.code }}</td>
                    <td>{{ role.name }}</td>
                    <td>
                      <CButton class="mr-1 btn-sm" @click="edit(i)" color="warning"><CIcon name="cil-pencil"/></CButton>
                      <CButton class="mr-1 btn-sm" @click="confirmDelete(i)" color="danger"><CIcon name="cil-trash"/></CButton>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      :show.sync="modal"
      :no-close-on-backdrop="true"
      :centered="true"
      title="Modal title 2"
      size="lg"
      color="info"
    >
      <CRow>
        <CCol sm="6">
          <CInput
            label="Code"
            v-model="frm.code"
            placeholder="Enter Code"
          />
        </CCol>
        <CCol sm="6">
          <CInput
            label="Name"
            v-model="frm.name"
            placeholder="Name"
          />
        </CCol>
      </CRow>
      <template #header>
        <h6 class="modal-title">{{ selectedId !='' ? 'Edit '+frm.name : 'Add new data'}}</h6>
        <CButtonClose @click="modal = false" class="text-white"/>
      </template>
      <template #footer>
        <CButton @click="modal = false" color="danger">CANCEL</CButton>
        <CButton @click="create" :disabled="loading" color="success">SAVE</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
export default {
  
  data: function () {
    return {
      roles: null,
      frm:{
        code:'',
        name:''
      },
      selectedId:'',
      modal:false,
      loading:false
    };
  },

  mounted(){
    this.getData()
  },

  methods:{
    async getData(){
      this.roles = []
      const {data} = await this.$http.get('acl/role')

      this.roles = data.data
    },
    confirmDelete(i){
      const vm = this
      const name = vm.roles[i].name
      const code = vm.roles[i].code

      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this! "+name,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3bb858',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        if (result.isConfirmed) {

          try {
            const {data} = await vm.$http.delete('acl/role', { params: { code: code }} )  
            if(data.status=='success'){
              vm.$toast.success('Success ! '+data.message)
              vm.getData()
            }else{
              vm.$swal('Fail !', data.message, 'danger')
            }    
          } catch (error) {
            vm.$swal('Error !', error.message, 'danger')
          }

          
        }
      })
    },
    edit(i){
      this.modal = true
      const data = JSON.parse(JSON.stringify(this.roles[i]))
      this.selectedId = data.code

      this.frm={
        code:data.code,
        name:data.name
      }
    },
    add(){
      this.frm={
        code:'',
        name:''
      }
      this.selectedId = ''
      this.modal = true
    },

    async create(){
      this.loading = true
      
      try {
        let datar = {}
        if(this.selectedId){
          const {data} = await this.$http.put('acl/role', this.frm, {params:{code:this.selectedId}})
          datar = data
        }else{
          const {data} = await this.$http.post('acl/role', this.frm)
          datar = data
        }
        if(datar.status=='success'){
          this.$toast.success('Success ! '+datar.message)
          this.getData()
          this.modal = false
        }else{
          this.$swal('Fail !', datar.message, 'danger')
        }
      } catch (error) {
        this.$swal('Error !', error.message, 'danger')
      }
      this.loading = false
    },

    refresh(){
      this.getData()
    }
  }
}
</script>